import { Theme } from "baseui";

export const STYLES = {
  container: (theme: Theme) => ({
    alignItems: "center",
    justifyContent: "center",
    minHeight: "190px",
    padding: `${theme.sizing.scale1600} ${theme.sizing.scale900} 0 ${theme.sizing.scale900}`,
    [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      flexDirection: "column",
    },
  }),
  wrapper: (theme: Theme) => ({
    marginLeft: theme.sizing.scale900,
    maxWidth: "650px",
    [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      marginLeft: "0px",
      marginTop: theme.sizing.scale950,
    },
  }),
};
