// used to query the API
// injected into translations
// used to query lp banner image at:
// public/images/{{country.name}}/banner.jpg
// public/images/{{country.name}}/banner@2x.jpg
const country = {
  country: {
    isos: {
      country: "BR",
      network: "BR",
    },
    isRegional: false,
    name: "Brazil",
    region: "LATAM",
  },
};

// used to personalize theme colors
const theme = {
  theme: {
    colors: {
      logo: {
        country: "#2d6e2f",
        frame: "#3A4155",
        primary: "#265534",
        secondary: "#F7B500",
      },
      lp: {
        backgroundPattern: "linear-gradient(121deg, #2D683F 50%, #C69100 100%)",
        benefitsIconDetail: "#DC9D43",
        primaryButton: "#C25400",
        primaryButtonHover: "#9a4f18",
        purchaseFormLineDetail: "#DB5E00",
      },
    },
    custom: {
      backgroundColor: "#F3F3F3",
      banner: {
        backgroundPosition: "unset",
        backgroundPositionLarge: "calc(-200px - 35%) 0%",
        backgroundPositionMedium: "calc(-300px - 35%)  0%",
        useBannerAsPattern: {
          backgroundPosition: "38% center",
          isEnabled: "false",
          opacity: "0.4",
        },
      },
      footerColor: "#444444",
      foregroundColor: "#F3F3F3",
      headerColor: "#FFF",
    },
  },
};

// default site translation
export default {
  ...country,
  ...theme,
  access: {
    buttons: {
      cancel: "Cancel",
      confirm: "Confirm PIN",
      enter: "Manage Your Order",
    },
    caption: "Enter your order ID below to proceed.",
    confirmation: {
      caption:
        "We've sent an email and SMS with the pin code to your registered contact details.",
      hintLeft: "Please check your email",
      hintRight: "to find it.",
      title: "Enter the PIN Code",
    },
    form: {
      order: "Order Number",
    },
    title: "Welcome!",
  },
  compatibility: {
    android: "Android Devices",
    confirm: "Got It",
    ios: "iOS Devices",
    title: "Device Compatibility",
  },
  coverage: {
    confirm: "Got It",
    info: {
      extended:
        "The available plans provide full coverage throughout all of {{region}}.",
      first: "We will guide you through the activation process after purchase.",
      fourth:
        "If you don't manage to activate your plan, you will be eligible for a refund.",
      second: "Your device must be compatible and network unlocked.",
      third: "No SMS or voice calls included, our plans are data only.",
      title: "Additional Information",
    },
    network: {
      provider: "Provider",
      speed: "Available speed",
      title: "Networks available for {{country}}",
    },
    title: "Coverage Information",
  },
  footer: {
    caption:
      "Our mission is to revolutionize the way people connect by offering innovative, flexible, and reliable eSIM options. With a focus on customer satisfaction and technological excellence, we strive to make global communication easier and more accessible for the busy, on-the-go lifestyle.",
    copyright: "©{{year}} STAY CONNECTED {{country}}",
    privacy: {
      label: "Privacy & Cookie Policy",
      url: "https://api.stayconnectedsim.com/public/privacy/P-2024-08-07.pdf",
    },
    social: {
      facebook: "https://www.facebook.com/profile.php?id=61565825608952",
      instagram: "https://www.instagram.com/stay.connected.sim",
      title: "Need help? Connect with us!",
    },
    terms: "Terms & Conditions",
    title: "Stay Connected is a world-class eSIM provider.",
  },
  header: {
    long: {
      manage: "Manage your eSIM",
      purchase: "Purchase Yours",
    },
    short: {
      manage: "Manage",
      purchase: "Purchase",
      support: "Support",
    },
  },
  loading: {
    title: "Loading...",
  },
  lp: {
    banner: {
      button: "Purchase Now",
      caption: "Instant data eSIM with no hidden fees and fast speeds!",
      headingFirstLine: "Stay Connected,",
      headingSecondLine: "Anywhere in {{country}}",
      warranty: [
        "Easy to install",
        "Fast 5G connection",
        "100% refund guaranteed",
        "English customer support",
      ],
    },
    benefits: {
      button: "Purchase Yours",
      caption: "",
      heading: "Why Choose Stay Connected Data Plans?",
      items: {
        fifth: {
          caption:
            "You get fast connectivity anywhere in the country using the latest 5G technology!",
          heading: "Fast 5G Speeds",
          icon: "speed",
        },
        first: {
          caption:
            "You will save up to 85% compared to roaming fees with your home carrier!",
          heading: "Save Money",
          icon: "money",
        },
        fourth: {
          caption:
            "We guarantee a 100% refund in case you are not able to use our product!",
          heading: "100% Refund Policy",
          icon: "refund",
        },
        second: {
          caption:
            "You will save time by buying and installing your data plan on your phone in minutes!",
          heading: "Save Time",
          icon: "clock",
        },
        sixth: {
          caption:
            "Get 24/7 support from our dedicated English-speaking support team!",
          heading: "English Support",
          icon: "support",
        },
        third: {
          caption:
            "Travel dates not fixed? You can activate your eSIMs anytime within 12 months of purchase!",

          heading: "Buy Now, Use Later",
          icon: "schedule",
        },
      },
    },
    description: {
      button: "See Offers",
      caption1:
        "Picture landing in {{country}}, excited to explore, only to discover your mobile service is dead or you're stuck with high roaming fees.",
      caption2:
        "Staying connected while traveling should not be a headache. Our data eSIM offers instant internet access, no physical SIMs or waiting in line. Enjoy seamless connectivity as soon as you arrive!",
      heading: "Will you have data when you arrive in {{country}}?",
    },
    help: {
      faq: {
        caption:
          "Check if your question is already answered in our FAQ, or send us a message, and we'll get back to you promptly.",
        heading: "Frequently Asked Questions",
        primaryButton: "Read the full FAQ",
        primaryButtonUrl:
          "https://api.stayconnectedsim.com/public/faq/F-2024-09-12.pdf",
      },
      heading: "Need Help?",
      support: {
        caption:
          "Our support team is ready to help you with any questions or issues you may have.",
        heading: "Still need help? Reach&nbsp;out&nbsp;to&nbsp;support!",
        secondaryButton: "Send us a message",
        secondaryButtonUrl: "mailto:support@stayconnectedsim.com",
      },
    },

    hero: {
      benefits: {
        first: {
          caption:
            "Enjoy robust coverage and high-speed data wherever you are.",
          heading: "Reliable Network:",
        },
        fourth: {
          caption:
            "Get the best value for your money with our affordable and transparent pricing.",
          heading: "Competitive Pricing:",
        },
        second: {
          caption:
            "Find a plan that fits your lifestyle, whether you are a frequent traveler or a heavy data user.",
          heading: "Flexible Plans:",
        },
        third: {
          caption:
            "Our dedicated support team is here to assist you with any questions or issues.",
          heading: "24/7 Support:",
        },
      },
      button: "Stay Connected",
      caption:
        "See how our eSIM solutions helped explorers from around the world Stay Connected in {{country}}.",
      heading: "Our Happy Travelers",
    },
    offers: {
      expiresIn: "Expires in {{minutes}}",
      percent: "{{percent}}% OFF",
      specialOffer: "Special Offer",
      timeOffer: "Time Limited Offer",
    },
    pricing: {
      caption:
        "Whether it's a single-week trip or a month-long stay, we’ve got you covered. Find the option that suits you best!",
      heading: "Find the right plan for your needs",
      item: {
        button: "Buy Now",
        caption: "Ideal for a {{duration}} trip to {{country}}",
        duration: "{{duration}}, unthrottled",
        each: "each",
        highlight: "Best Value",
        previously: "previously",
      },
    },
  },
  manage: {
    buttons: {
      logout: "Logout",
    },
    cards: {
      autoTopUp: {
        hint: "Auto top-up is currently enabled",
        states: {
          first: {
            button: "Enable Auto Top-Up!",
            description:
              "Automatic top-up is a convenient feature that ensures you never run out of data. When your data balance is low, this feature will automatically add more data and charge your credit card for each top-up. This way, you can stay connected and continue using your apps without interruptions.",
          },
          second: {
            bundle: "Auto Top-Up Bundle",
            button: "Enable Auto Top-Up!",
            caption:
              "Select an auto top-up bundle, this bundle will be automatically purchased and attached to the esim when your data is running low",
            check: "Confirm auto top-up terms",
            dismiss: "Dismiss",
            terms:
              "I acknowledge and agree that by enabling auto top-up, my original payment method will be charged when my data usage reaches 80%, ensuring seamless connectivity and preventing any service disruption.",
          },
          third: {
            button: "Got It!",
            description:
              "Thank you for enabling auto top-up! When your data balance is low, your eSIM will be automatically topped up. We appreciate your trust in our service. Stay Connected!",
          },
        },
        subtitle: "For Order {{order}}",
        title: "Enable Auto Top-Up",
      },
      customer: {
        orders: "Your Orders",
        title: "Customer",
      },
      manualTopUp: {
        states: {
          first: {
            button: "Top me up!",
            description:
              "A top-up is the process of adding more data or extending the validity of your existing bundle. When you run low on data or your plan is about to expire, you can purchase a top-up to ensure continued connectivity. This allows you to stay online, and use your apps without interruption.",
          },
          second: {
            button: "Dismiss",
          },
          third: {
            button: "Got It!",
            description:
              "Thank you for your purchase! Your eSIM top-up is currently being processed. We appreciate your patience and continued trust in our service. You will be notified once the process is complete. Stay Connected!",
          },
        },
        subtitle: "For Order {{order}}",
        title: "Manual Top-Up",
      },
      optOut: {
        hint: "Auto top-up is currently disabled",
        states: {
          first: {
            button: "Opt me out!",
            description:
              "Opting out of the auto top-up feature means you are choosing to disable the automatic replenishment of your data when it reaches a certain threshold. This means your card will no longer be charged automatically, and you will need to manually top-up your eSIM data to maintain service. This option gives you full control over your data purchases and spending.",
          },
          second: {
            button: "Disable Auto Top-Up!",
            description:
              "Are you sure you want to opt out of the auto top-up feature? This will disable automatic data replenishment, and you will need to manually top-up your eSIM data to maintain service. Please confirm your choice by clicking the button below.",
            dismiss: "Dismiss",
          },
          third: {
            button: "Got It!",
            description:
              "Your opt-out from the auto top-up feature has been successfully confirmed. You will now need to manually top-up your eSIM as needed. Thank you for managing your preferences with us!",
          },
        },
        subtitle: "For Order {{order}}",
        title: "Disable Auto Top-Up",
      },
      payments: {
        fifthColumn: "Paid At",
        firstColumn: "Order Ids",
        fourthColumn: "Price",
        secondColumn: "Type",
        thirdColumn: "Status",
      },
    },
    declined: {
      caption:
        "Unfortunately, your payment was declined by your credit card, so we are unable to deliver this order. We invite you to place a new order using an alternative payment method at your convenience.",
      title: "Payment Declined",
    },
    tabs: {
      first: "Your Order",
      second: "Payments",
      third: "Top-Up",
    },
  },
  notification: {
    allow: "Allow Notifications",
    allowSmall: "Enable",
    later: "Maybe Later",
    laterSmall: "Not Now",
    subtitle:
      "Get notified about exclusive offers and important updates on your data usage.",
    title: "Enable Notifications",
  },
  purchase: {
    amount: {
      label: "How many eSIMs do you need?",
    },
    autoTopUp: {
      caption:
        "When enabled, your card will be charged when data usage reaches 80%, preventing service disruption. You can disable this feature anytime.",
      label: "Enable auto top-up",
    },
    bundle: {
      change: "change",
      description: "{{amount}} for {{duration}} in {{country}}",
      label: "Select Your Plan",
      labelSelected: "Your Plan",
      select: "select",
    },
    confirmButton: "Confirm Payment",
    coverage: "The following options cover all of {{region}}",
    heading: "Ready to get connected?",
    more: {
      compatibilityButton: "Device Compatibility",
      coverageInfoButton: "Coverage Information",
      dataGuideButton: "Data Usage Guide",
      entry: "More Information",
    },
    paymentInfo: {
      card: "Card Number",
      creditCardButton: "Pay With Credit Card",
      creditCardInfo: "You are paying with a Credit Card",
      cvc: "Card Verification Code",
      expiry: "Card Expiry Date",
      heading: "Payment Information",
      useWallet: "Use Your Wallet",
    },
    personalInfo: {
      email: {
        caption:
          "Please ensure you have access to this email, as installation instructions and PIN codes will be sent there.",
        label: "Email Address",
      },
      heading: "Personal Information",
      name: "Full Name",
    },
    terms: {
      label: "I agree to the",
      link: "terms and conditions.",
    },
    total: {
      label: "Your current order total:",
    },
  },
  typ: {
    caption:
      "Your eSIMs will be delivered to your email as soon as we confirm your payment.",
    headings: {
      next: "What's next?",
      order: "Your order details",
      title: "Your order is complete",
    },
    items: {
      first: {
        caption:
          "We've sent you a confirmation email with your order details and instructions on how to activate your eSIM.",
        title: "Check your email",
      },
      second: {
        caption:
          "Follow the simple steps in the email to set up your eSIM and start enjoying seamless connectivity.",
        title: "Activate your eSIM",
      },
      third: {
        caption:
          "You can manage your eSIM through our website by clicking on “Manage your eSIM” at any time after activation. All you need is your Order ID.",
        title: "Check your data",
      },
    },
    tables: {
      autoTopUp: "Auto top-up settings",
      autoTopUpStatus:
        "You {{status}} the auto top-up settings for this purchase.",
      bundle: "Bundle",
      customer: "Customer",
      email: "E-mail",
      order: "Order",
      price: "Price",
    },
  },
};
