import { Theme } from "baseui";

export const STYLES = {
  container: (theme: Theme) => ({
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    padding: `${theme.sizing.scale1600} ${theme.sizing.scale900}`,
  }),
  image: (theme: Theme) => ({
    marginTop: theme.sizing.scale900,
  }),

  scrollable: () =>
    ({
      "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
      ["::-webkit-scrollbar"]: {
        display: "none",
      },
      maxWidth: "calc(100vw - 128px)",
      overflowX: "scroll",
      scrollbarWidth: "none" /* Firefox */,
      scrollSnapType: "x mandatory",
      width: "904px",
    }) as const,
  scrollableContent: () =>
    ({
      justifyContent: "center",
      minWidth: "fit-content",
      paddingTop: "36px",
      width: "100%",
    }) as const,
  textWrapper: (theme: Theme) =>
    ({
      marginBottom: theme.sizing.scale900,
      textAlign: "center",
    }) as const,
  wrapper: (theme: Theme) =>
    ({
      maxWidth: "1578px",
      [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
        flexDirection: "row",
      },
      [`@media screen and (min-width: ${theme.breakpoints.medium}px)`]: {
        flexDirection: "row",
      },
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        alignItems: "center",
        flexDirection: "column-reverse",
      },
    }) as const,
};
