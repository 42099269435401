import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Block } from "baseui/block";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";

import { useTranslation } from "../hooks/translate";
import { ApiProvider } from "../providers/api-provider";
import { StripeWalletProvider } from "../providers/api-provider/stripe";
import AppRoutes from "./routes";
import ThemeProvider from "./theme";

const engine = new Styletron();
const stripePromise = loadStripe(
  process.env.NODE_ENV === "development"
    ? "pk_test_51Pb5czGEW7E4y3uyvCe5DfulfAfLULHLykmpUk835t7d3KPsMIwK6XRfDvUA26ewgi8CJZTlRnUQ2FMfBRz5Y9Ub009apE3sNg"
    : "pk_live_51Pb5czGEW7E4y3uyf2AKSACXdOeW9XbOnXOa1AKlpDP6TyD47xIeGMnWCa2u9cOUtFHpfbmaL1puqo4uyEkAXioZ00DRIMPs7F",
);

const Root = (): React.ReactNode => {
  const { translate } = useTranslation();
  const country = translate("country.name");

  const keywords = [
    `esim bundles for travel to ${country}`,
    `data for travel to ${country}`,
    `prepaid data plan in ${country}`,
    `${country} sim card`,
    `${country} travel data plans`,
    `${country} esim`,
    `mobile data in ${country}`,
    `tourist esim in ${country}`,
    `${country} internet`,
    `${country} data packages`,
    `esim for ${country}`,
    `prepaid sim cards ${country}`,
    `international data plans`,
    `${country} travel`,
    `best esim for ${country}`,
    `cheap data plans ${country}`,
    `esim deals ${country}`,
    `${country} mobile internet`,
    `data roaming in ${country}`,
    `travel connectivity ${country}`,
  ];

  return (
    <StyletronProvider debugAfterHydration value={engine}>
      <HelmetProvider>
        <Helmet>
          <title>Stay Connected in {country}</title>
          <meta
            content="width=device-width, initial-scale=1 initial-scale=1.0, maximum-scale=5.0, user-scalable=yes"
            name="viewport"
          />
          <meta
            content={`Discover the best esim bundles, data for travel, and prepaid data plans available in ${country} to stay connected while exploring.`}
            name="description"
          />
          <meta content={keywords.join(", ")} name="keywords" />
          <style type="text/css">{"body{margin: 0px;}"}</style>
        </Helmet>
        <ThemeProvider>
          <ApiProvider>
            <Elements
              options={{
                amount: 1099,
                currency: "usd",
                mode: "payment",
                paymentMethodCreation: "manual",
              }}
              stripe={stripePromise}
            >
              <StripeWalletProvider>
                <AppRoutes />
                <ToasterContainer
                  autoHideDuration={2500}
                  overrides={{
                    Root: {
                      style: {
                        zIndex: 2,
                      },
                    },
                  }}
                  placement={PLACEMENT.topRight}
                />
                <Block
                  id="modal-mount-node"
                  style={{ position: "fixed", zIndex: 2 }}
                />
              </StripeWalletProvider>
            </Elements>
          </ApiProvider>
        </ThemeProvider>
      </HelmetProvider>
    </StyletronProvider>
  );
};

export default Root;
