import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeElements } from "@stripe/stripe-js";
import { useStyletron } from "baseui";
import { Badge } from "baseui/badge";
import { Button } from "baseui/button";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { StyledDivider } from "baseui/divider";
import { FormControl } from "baseui/form-control";
import { ArrowRight } from "baseui/icon";
import { Input } from "baseui/input";
import { StyledLink } from "baseui/link";
import { Stepper } from "baseui/stepper";
import { toaster } from "baseui/toast";
import { HeadingLarge, LabelMedium, ParagraphSmall } from "baseui/typography";
import * as React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { PurchaseRequestT, PurchaseTopUpRequestT } from "../../api/types";
import { useTranslation } from "../../hooks/translate";
import { usePromotions } from "../../hooks/use-promotions";
import { ApiContext } from "../../providers/api-provider";
import { StripeWalletContext } from "../../providers/api-provider/stripe";
import { BundleT } from "../../providers/api-provider/types";
import { scrollWithOffset } from "../../utils";
import { Mixpanel } from "../../utils/mixpanel";
import {
  checkValueFor,
  validateEmail,
  validateFullName,
} from "../../utils/validations";
import { CompatibleDevices } from "../compatible-devices";
import { Column, Row } from "../containers";
import { CoverageInformation } from "../coverage-information";
import { DataUsageGuide } from "../data-usage-guide";
import If from "../if";
import { MoreInformation } from "../more-information";
import {
  FontFamily,
  FORM_CONTROL_CAPTION_OVERRIDE,
  FORM_CONTROL_LABEL_OVERRIDE,
  USE_FONT,
} from "../overrides";
import { StripeInput } from "../stripe-input";
import { StripeWallet } from "../stripe-wallet";
import { STYLES } from "./styles";

export type PurchaseRequestExtrasT = PurchaseRequestT & {
  is_wallet?: boolean;
  payer_email?: string;
  payer_name?: string;
};

export type PurchaseTopUpRequestWithExtrasT = PurchaseTopUpRequestT & {
  is_wallet?: boolean;
};

export type PurchaseFormSubmitT = {
  elements: StripeElements;
  form: PurchaseRequestExtrasT | PurchaseTopUpRequestWithExtrasT;
  walletCallback?: (status: boolean, clientSecret?: string) => Promise<boolean>;
};

export enum EnumPurchaseFormType {
  Purchase,
  TopUp,
}

const INPUT_WITH_ERROR_OVERRIDE = (hasError: boolean) => ({
  Input: {
    style: {
      ...STYLES.inputWithError(hasError),
    },
  },
});

export const PurchaseForm = ({
  formType,
  isAutoTopUpEnabled,
  isLoading,
  onSubmit,
}: {
  formType: EnumPurchaseFormType;
  isAutoTopUpEnabled: boolean;
  isLoading: boolean;
  onSubmit: ({ elements, form }: PurchaseFormSubmitT) => Promise<boolean>;
}) => {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { isPromo, percentOff, previousValue } = usePromotions();
  const routeParams = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const apiContext = React.useContext(ApiContext);
  const { supportWallet } = React.useContext(StripeWalletContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCompatibleDeviceListOpen, setIsCompatibleDeviceListOpen] =
    React.useState(false);
  const [isDataGuideOpen, setIsDataGuideOpen] = React.useState(Boolean(false));
  const [isCoverageInformationOpen, setIsCoverageInformationOpen] =
    React.useState(Boolean(false));
  const [form, setForm] = React.useState<PurchaseRequestExtrasT>({
    auto_top_up: false,
    bundle_name: "",
    customer_name: "",
    email: "",
    is_wallet: false,
    origin: window.location.hostname,
    payment_method_id: undefined,
    quantity: 1,
    return_url:
      formType === EnumPurchaseFormType.Purchase
        ? `${window.location.origin}/thank-you`
        : window.location.href,
    terms_id: "",
  });
  const [formErrors, setFormErrors] = React.useState({
    customer_name: false,
    email: false,
  });
  const [payWithCreditCard, setPayWithCreditCard] = React.useState(
    supportWallet ? false : true,
  );
  const [isCardNumberValid, setIsCardNumberValid] = React.useState(false);
  const [isCardExpiryValid, setIsCardExpiryValid] = React.useState(false);
  const [isCardCvcValid, setIsCardCvcValid] = React.useState(false);

  React.useEffect(() => {
    if (!apiContext.bundles?.length) {
      return;
    }
    const shouldAutoScroll = searchParams.has("auto-scroll");
    const shouldOpenDeviceList = searchParams.has("device-compatibility");
    const shouldOpenDataGuide = searchParams.has("data-usage-guide");
    // should open device-compatibility list
    if (shouldOpenDeviceList) {
      searchParams.delete("device-compatibility");
      setIsCompatibleDeviceListOpen(shouldOpenDeviceList);
    }
    // should open data-usage-guide list
    if (shouldOpenDataGuide) {
      searchParams.delete("data-usage-guide");
      setIsDataGuideOpen(shouldOpenDataGuide);
    }
    // should auto-scroll
    if (shouldAutoScroll) {
      searchParams.delete("auto-scroll");
      setTimeout(() => scrollWithOffset("#purchase-form", -240, "smooth"), 250);
    }
    setSearchParams({
      ...searchParams,
    });
  }, [apiContext.bundles, searchParams]);

  React.useEffect(() => {
    if (supportWallet) {
      setPayWithCreditCard(false);
    }
  }, [supportWallet]);

  React.useEffect(() => {
    if (formType === EnumPurchaseFormType.Purchase && routeParams.id) {
      setForm((form) => ({
        ...form,
        bundle_name: routeParams.id as string,
      }));
      navigate(`/`, { replace: true });
    }
  }, [formType, routeParams.id]);

  const bundle = React.useMemo(() => {
    return apiContext.bundles?.find((b) => b.name === form.bundle_name);
  }, [apiContext.bundles, form.bundle_name]);

  const checkForm = React.useCallback(
    (card: boolean) => {
      const surfaceWarning = (msg: string) => {
        toaster.warning(msg);
        Mixpanel.track("Purchase", {
          action: "check-form-failure",
          issue: msg,
        });
      };
      if (!bundle) {
        surfaceWarning("Select your plan");
        return false;
      }
      if (
        formType === EnumPurchaseFormType.Purchase &&
        !checkValueFor(form.quantity)
      ) {
        surfaceWarning("Select the quantity");
        return false;
      }
      if (!checkValueFor(form.bundle_name)) {
        surfaceWarning("Select your plan");
        return false;
      }
      if (
        formType === EnumPurchaseFormType.Purchase &&
        !validateFullName(form.customer_name)
      ) {
        surfaceWarning("Provide a proper customer full name");
        return false;
      }
      if (
        formType === EnumPurchaseFormType.Purchase &&
        !validateEmail(form.email)
      ) {
        surfaceWarning("Provide a proper email address");
        return false;
      }

      if (card) {
        if (!isCardNumberValid) {
          surfaceWarning("Provide a proper card number");
          return false;
        }
        if (!isCardExpiryValid) {
          surfaceWarning("Provide a proper card expiry date");
          return false;
        }
        if (!isCardCvcValid) {
          surfaceWarning("Provide a proper card verification code");
          return false;
        }
      }

      if (
        formType === EnumPurchaseFormType.Purchase &&
        !checkValueFor(form.terms_id)
      ) {
        surfaceWarning("Please accept the terms and conditions");
        return false;
      }

      Mixpanel.track("Purchase", {
        action: "check-form-success",
      });
      return true;
    },
    [
      bundle,
      form,
      isCardNumberValid,
      isCardExpiryValid,
      isCardCvcValid,
      formType,
    ],
  );

  const onWalletSubmit = React.useCallback(
    async (
      submitForm: object,
    ): Promise<{ clientSecret?: string; status: boolean }> => {
      setForm(submitForm as PurchaseRequestExtrasT);
      let clientSecret: string | undefined = undefined;
      let status = false;
      await onSubmit({
        elements: elements as StripeElements,
        form: submitForm as PurchaseRequestExtrasT,
        walletCallback: async (sts, cs) => {
          status = sts;
          clientSecret = cs;
          return status;
        },
      });
      return { clientSecret, status };
    },
    [stripe, elements, form],
  );

  const total = React.useMemo(() => {
    if (!bundle || !checkValueFor(form.quantity)) {
      return 0;
    }
    return bundle.price * form.quantity;
  }, [form, bundle]);

  const preSubmit = React.useCallback(() => {
    if (!checkForm(true)) {
      return;
    }
    if (!bundle) {
      return;
    }
    Mixpanel.track("Purchase", {
      action: "confirmed",
      bundle: form.bundle_name,
      method: "card",
      price: bundle.price,
      quantity: form.quantity,
      total: bundle.price * form.quantity,
      type: formType.toString().toLocaleLowerCase(),
    });
    onSubmit({
      elements: elements as StripeElements,
      form: { ...form, payment_type: "card" },
      walletCallback: undefined,
    });
  }, [checkForm, elements, bundle, form, formType]);

  const renderProductItem = React.useCallback(
    (bundle: BundleT | undefined) => {
      if (!bundle) {
        return null;
      }
      const index =
        apiContext.bundles?.findIndex((b) => b.name === bundle?.name) || 0;
      return (
        <Column
          style={{
            marginRight: theme.sizing.scale300,
            textAlign: "left",
          }}
        >
          <LabelMedium
            style={{
              ...USE_FONT(FontFamily.DMSansMedium, 500, "20px", "16px"),
              marginBottom: theme.sizing.scale200,
            }}
          >
            {translate("purchase.bundle.description", {
              ...bundle.attributes,
            })}
          </LabelMedium>
          <LabelMedium
            style={{
              ...USE_FONT(FontFamily.DMSans, 500, "20px", "16px"),
              alignItems: "center",
              display: "flex",
            }}
          >
            {isPromo(index) && (
              <React.Fragment>
                <Badge
                  color="negative"
                  content={translate("lp.offers.percent", {
                    percent: percentOff(index, bundle.price),
                  })}
                  overrides={{
                    Badge: {
                      style: {
                        fontSize: "10px",
                        marginRight: "8px",
                      },
                    },
                  }}
                />
                <span
                  style={{ fontSize: "14px" }}
                >{`$${previousValue(index, bundle.price)}`}</span>
                <ArrowRight size={"20px"} />
              </React.Fragment>
            )}
            <strong
              style={USE_FONT(FontFamily.DMSansBold, 500, "20px", "16px")}
            >{`US$ ${bundle.price.toFixed(0)}`}</strong>
          </LabelMedium>
        </Column>
      );
    },
    [USE_FONT, apiContext.bundles],
  );

  const renderOptions = React.useCallback(
    (shouldRender: boolean) => {
      if (!shouldRender) {
        return null;
      }
      return (
        <React.Fragment>
          <If condition={isAutoTopUpEnabled}>
            <FormControl
              caption={() => translate("purchase.autoTopUp.caption")}
            >
              <Checkbox
                checked={form.auto_top_up}
                labelPlacement={LABEL_PLACEMENT.right}
                onChange={(e) =>
                  setForm({ ...form, auto_top_up: e.target.checked })
                }
              >
                {translate("purchase.autoTopUp.label")}
              </Checkbox>
            </FormControl>
          </If>
          <If condition={formType === EnumPurchaseFormType.Purchase}>
            <FormControl>
              <Checkbox
                checked={Boolean(form.terms_id)}
                disabled={!apiContext.terms}
                labelPlacement={LABEL_PLACEMENT.right}
                onChange={(e) =>
                  setForm({
                    ...form,
                    terms_id: e.target.checked
                      ? (apiContext.terms?.id as string)
                      : "",
                  })
                }
              >
                <LabelMedium style={{ lineHeight: "24px" }}>
                  {translate("purchase.terms.label")}
                  <StyledLink
                    href={apiContext.terms?.terms_url}
                    style={{ paddingLeft: theme.sizing.scale200 }}
                    target="_blank"
                  >
                    {translate("purchase.terms.link")}
                  </StyledLink>
                </LabelMedium>
              </Checkbox>
            </FormControl>
          </If>
          <Row>
            <StyledDivider
              $size={"section"}
              style={{ marginBottom: theme.sizing.scale600, width: "100%" }}
            />
          </Row>
          <Row
            style={{
              justifyContent: "space-between",
            }}
          >
            <LabelMedium> {translate("purchase.total.label")}</LabelMedium>
            <LabelMedium>{`US$ ${total.toFixed(2)}`}</LabelMedium>
          </Row>
          <Row>
            <StyledDivider
              $size={"section"}
              style={{
                marginBottom: theme.sizing.scale600,
                marginTop: theme.sizing.scale600,
                width: "100%",
              }}
            />
          </Row>
        </React.Fragment>
      );
    },
    [total, form, apiContext.terms, formType, isAutoTopUpEnabled, translate],
  );

  return (
    <Column id="purchase-form">
      <If condition={formType === EnumPurchaseFormType.Purchase}>
        <FormControl
          label={() => translate("purchase.amount.label")}
          overrides={FORM_CONTROL_LABEL_OVERRIDE}
        >
          <Column
            style={{
              alignItems: "center",
              justifyContent: "end",
              minHeight: theme.sizing.scale1600,
            }}
          >
            <Stepper
              maxValue={9}
              minValue={1}
              overrides={{
                DecrementButton: {
                  style: {
                    height: theme.sizing.scale1200,
                    width: theme.sizing.scale1200,
                  },
                },
                Input: {
                  component: ({ value }) => (
                    <HeadingLarge className={css(STYLES.stepperInput(theme))}>
                      {value}
                    </HeadingLarge>
                  ),
                },
                Root: {
                  style: { maxWidth: "100%" },
                },
              }}
              setValue={(newValue) =>
                setForm((form) => ({
                  ...form,
                  quantity: newValue,
                }))
              }
              value={form.quantity}
            />
          </Column>
        </FormControl>
      </If>
      <FormControl
        label={
          <Row
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <LabelMedium
              style={{
                ...USE_FONT(FontFamily.DMSans, 500, "20px", "16px"),
                marginRight: theme.sizing.scale300,
              }}
            >
              {bundle
                ? translate("purchase.bundle.labelSelected")
                : translate("purchase.bundle.label")}
            </LabelMedium>
            <If condition={formType === EnumPurchaseFormType.Purchase}>
              <MoreInformation
                setIsCompatibleDeviceListOpen={setIsCompatibleDeviceListOpen}
                setIsCoverageInformationOpen={setIsCoverageInformationOpen}
                setIsDataGuideOpen={setIsDataGuideOpen}
              />
            </If>
          </Row>
        }
      >
        <React.Fragment>
          <If condition={Boolean(bundle)}>
            <Button
              endEnhancer={
                <ParagraphSmall
                  margin={0}
                  style={{
                    ...USE_FONT(FontFamily.DMSans, 500, "18px", "16px"),
                    background: theme.colors.gray800,
                    borderRadius: "8px",
                    color: theme.colors.contentInversePrimary,
                    padding: "4px 8px",
                  }}
                >
                  {translate("purchase.bundle.change")}
                </ParagraphSmall>
              }
              kind="secondary"
              onClick={() => {
                setForm({ ...form, bundle_name: "" });
              }}
              overrides={{
                BaseButton: {
                  style: {
                    justifyContent: "space-between",
                    marginBottom: theme.sizing.scale300,
                    width: "100%",
                  },
                },
              }}
            >
              {renderProductItem(bundle)}
            </Button>
          </If>
          <If condition={!bundle}>
            {(apiContext.bundles || []).map((bundle) => (
              <Button
                endEnhancer={
                  <ParagraphSmall
                    margin={0}
                    style={{
                      ...USE_FONT(FontFamily.UberMoveText, 500, "18px", "14px"),
                      background: theme.colors.gray300,
                      borderRadius: "8px",
                      padding: "4px 8px",
                    }}
                  >
                    {translate("purchase.bundle.select")}
                  </ParagraphSmall>
                }
                key={bundle.name}
                kind="secondary"
                onClick={() => {
                  Mixpanel.track("Pricing", {
                    action: "selected",
                    at: "purchase-form",
                    bundle: bundle.name,
                    price: bundle.price,
                    type: formType,
                  });
                  setForm({ ...form, bundle_name: bundle.name });
                }}
                overrides={{
                  BaseButton: {
                    style: {
                      justifyContent: "space-between",
                      marginBottom: theme.sizing.scale300,
                      width: "100%",
                    },
                  },
                }}
              >
                {renderProductItem(bundle)}
              </Button>
            ))}
          </If>
        </React.Fragment>
      </FormControl>
      <If condition={formType === EnumPurchaseFormType.Purchase}>
        <Row
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: theme.sizing.scale300,
            marginTop: theme.sizing.scale300,
          }}
        >
          <StyledDivider $size={"section"} style={{ width: "100%" }} />
          <LabelMedium
            style={{
              ...USE_FONT(FontFamily.DMSansMedium, 500, "20px", "18px"),
              flexGrow: "1",
              marginLeft: theme.sizing.scale600,
              marginRight: theme.sizing.scale600,
              minWidth: "180px",
              textWrap: "nowrap",
            }}
          >
            {translate("purchase.personalInfo.heading")}
          </LabelMedium>
          <StyledDivider $size={"section"} style={{ width: "100%" }} />
        </Row>
        <FormControl
          label={() => translate("purchase.personalInfo.name")}
          overrides={FORM_CONTROL_LABEL_OVERRIDE}
        >
          <Input
            autoComplete="name"
            id="name"
            name="name"
            onBlur={() => {
              setFormErrors((formErrors) => ({
                ...formErrors,
                customer_name: !form.customer_name
                  ? false
                  : !validateFullName(form.customer_name),
              }));
            }}
            onChange={(e) => {
              setFormErrors((formErrors) => ({
                ...formErrors,
                customer_name: false,
              }));
              setForm((form) => ({
                ...form,
                customer_name: e.target.value,
              }));
            }}
            overrides={INPUT_WITH_ERROR_OVERRIDE(formErrors.customer_name)}
            value={form.customer_name}
          />
        </FormControl>
        <FormControl
          caption={() => translate("purchase.personalInfo.email.caption")}
          label={() => translate("purchase.personalInfo.email.label")}
          overrides={{
            ...FORM_CONTROL_CAPTION_OVERRIDE,
            ...FORM_CONTROL_LABEL_OVERRIDE,
          }}
        >
          <Input
            autoComplete="email"
            id="email"
            name="email"
            onBlur={() => {
              setFormErrors((formErrors) => ({
                ...formErrors,
                email: !form.email ? false : !validateEmail(form.email),
              }));
            }}
            onChange={(e) => {
              setFormErrors((formErrors) => ({
                ...formErrors,
                email: false,
              }));
              setForm((form) => ({ ...form, email: e.target.value }));
            }}
            overrides={INPUT_WITH_ERROR_OVERRIDE(formErrors.email)}
            type="email"
            value={form.email}
          />
        </FormControl>
      </If>
      {renderOptions(!payWithCreditCard)}
      <If condition={!payWithCreditCard}>
        <Button
          isLoading={isLoading}
          kind={"primary"}
          onClick={() => {
            Mixpanel.track("Purchase", {
              action: "selected",
              method: "card",
              type: formType.toString().toLocaleLowerCase(),
            });
            setPayWithCreditCard(true);
          }}
          overrides={{
            BaseButton: {
              style: {
                borderBottomLeftRadius: theme.sizing.scale100,
                borderBottomRightRadius: theme.sizing.scale100,
                borderTopLeftRadius: theme.sizing.scale100,
                borderTopRightRadius: theme.sizing.scale100,
                width: "100%",
              },
            },
          }}
        >
          {translate("purchase.paymentInfo.creditCardButton")}
        </Button>
      </If>
      <If condition={payWithCreditCard}>
        <If condition={!supportWallet}>
          <Row
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: theme.sizing.scale300,
              marginTop: theme.sizing.scale300,
            }}
          >
            <StyledDivider $size={"section"} style={{ width: "100%" }} />
            <LabelMedium
              style={{
                ...USE_FONT(FontFamily.DMSansMedium, 500, "20px", "18px"),
                flexGrow: "1",
                marginLeft: theme.sizing.scale600,
                marginRight: theme.sizing.scale600,
                minWidth: "180px",
                textWrap: "nowrap",
              }}
            >
              {translate("purchase.paymentInfo.heading")}
            </LabelMedium>
            <StyledDivider $size={"section"} style={{ width: "100%" }} />
          </Row>
        </If>
        <If condition={supportWallet}>
          <FormControl>
            <Column>
              <StyledDivider $size={"section"} style={{ width: "100%" }} />
              <Row
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: theme.sizing.scale600,
                  marginTop: theme.sizing.scale600,
                }}
              >
                <LabelMedium margin={0}>
                  {translate("purchase.paymentInfo.creditCardInfo")}
                </LabelMedium>
                <If condition={supportWallet}>
                  <Button
                    onClick={() => setPayWithCreditCard(false)}
                    size="mini"
                  >
                    {translate("purchase.paymentInfo.useWallet")}
                  </Button>
                </If>
              </Row>
              <StyledDivider $size={"section"} style={{ width: "100%" }} />
            </Column>
          </FormControl>
        </If>
        <FormControl
          label={() => translate("purchase.paymentInfo.card")}
          overrides={FORM_CONTROL_LABEL_OVERRIDE}
        >
          <StripeInput
            elementType="cardNumber"
            setIsValid={setIsCardNumberValid}
            targetId="card-number-element"
          />
        </FormControl>
        <Row>
          <Column style={{ flexGrow: "1", marginRight: theme.sizing.scale300 }}>
            <FormControl
              label={() => translate("purchase.paymentInfo.expiry")}
              overrides={FORM_CONTROL_LABEL_OVERRIDE}
            >
              <StripeInput
                elementType="cardExpiry"
                setIsValid={setIsCardExpiryValid}
                targetId="card-expiry-element"
              />
            </FormControl>
          </Column>
          <Column style={{ flexGrow: "1", marginLeft: theme.sizing.scale300 }}>
            <FormControl
              label={() => translate("purchase.paymentInfo.cvc")}
              overrides={FORM_CONTROL_LABEL_OVERRIDE}
            >
              <StripeInput
                elementType="cardCvc"
                setIsValid={setIsCardCvcValid}
                targetId="card-cvc-element"
              />
            </FormControl>
          </Column>
        </Row>
      </If>
      {renderOptions(payWithCreditCard)}
      <If condition={payWithCreditCard}>
        <Button
          isLoading={isLoading}
          onClick={preSubmit}
          overrides={{
            BaseButton: {
              style: {
                borderBottomLeftRadius: theme.sizing.scale100,
                borderBottomRightRadius: theme.sizing.scale100,
                borderTopLeftRadius: theme.sizing.scale100,
                borderTopRightRadius: theme.sizing.scale100,
              },
            },
          }}
        >
          {translate("purchase.confirmButton")}
        </Button>
      </If>
      <StripeWallet
        checkForm={checkForm}
        form={form}
        formType={formType}
        isHidden={payWithCreditCard}
        isLoading={isLoading}
        onWalletSubmit={onWalletSubmit}
        targetId="wallet-button"
      />
      <CompatibleDevices
        isOpen={isCompatibleDeviceListOpen}
        setIsOpen={setIsCompatibleDeviceListOpen}
      />
      <DataUsageGuide isOpen={isDataGuideOpen} setIsOpen={setIsDataGuideOpen} />
      <CoverageInformation
        isOpen={isCoverageInformationOpen}
        setIsOpen={setIsCoverageInformationOpen}
      />
    </Column>
  );
};
