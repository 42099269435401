import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { HeadingMedium, ParagraphMedium } from "baseui/typography";
import React from "react";

import { useTranslation } from "../../../hooks/translate";
import { scrollWithOffset } from "../../../utils";
import { Mixpanel } from "../../../utils/mixpanel";
import { Column, NoShrinkContainer, Row } from "../../containers";
import { Logo } from "../../logo/logo";
import { FontFamily, LP_BUTTON_PRIMARY, USE_FONT } from "../../overrides";
import { PromoButton } from "../../promo-button";
import { STYLES } from "./styles";

export const LpDescriptionSection = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();

  return (
    <Column>
      <Row className={css(STYLES.container(theme))}>
        <NoShrinkContainer $width={100}>
          <Logo fullWidth={false} size={100} />
        </NoShrinkContainer>
        <Column className={css(STYLES.wrapper(theme))}>
          <HeadingMedium
            style={{
              ...USE_FONT(FontFamily.SatoshiVariable, 700, "48px", "28px"),
              marginBottom: theme.sizing.scale300,
            }}
          >
            {translate("lp.description.heading")}
          </HeadingMedium>
          <ParagraphMedium
            style={USE_FONT(FontFamily.DMSans, 400, "32px", "16px")}
          >
            {translate("lp.description.caption1")}
          </ParagraphMedium>
          <ParagraphMedium
            style={{
              ...USE_FONT(FontFamily.DMSans, 400, "32px", "16px"),
              marginTop: theme.sizing.scale300,
            }}
          >
            {translate("lp.description.caption2")}
          </ParagraphMedium>
        </Column>
      </Row>
      <PromoButton
        content="On Sale Now"
        style={{
          marginBottom: theme.sizing.scale600,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale1600,
        }}
      >
        <Button
          onClick={() => {
            Mixpanel.track("CTA", {
              action: "click",
              element: "see-offers",
              section: "description-section",
            });
            scrollWithOffset("#pricing-section");
          }}
          overrides={LP_BUTTON_PRIMARY(theme, {
            ...USE_FONT(FontFamily.DMSans, 500, "20px", "18px"),
            paddingLeft: theme.sizing.scale1200,
            paddingRight: theme.sizing.scale1200,
          })}
        >
          {translate("lp.description.button")}
        </Button>
      </PromoButton>
    </Column>
  );
};
